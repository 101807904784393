.search-container {
  display: grid;
  grid-template-columns: max-content auto min-content;
  background-color: var(--colour-element);
  transition: var(--background-colour-transition);
  border-radius: var(--corner-full);
  align-items: center;
  padding: var(--inner-padding-m);
  padding-left: var(--inner-padding-r);
  gap: var(--gap-r);
  min-width: unset;
  width: 100%;
  max-width: 30em;
  justify-self: flex-end;
  
  &.full-width {
    max-width: unset;
  }
  
  --colour-text: var(--colour-element-text);
  
  &[aria-selected="true"] {
    box-shadow: 0 0 0 2px var(--colour-standout);
  }
  
  // &:has(input:active) {
  //   background-color: red;
  // }
  
  svg {
    width: 1.3rem;
  }
  
  input[type="text"] {
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    outline: unset;
    font-family: var(--subheading);
    min-width: 0;
    
    &::placeholder {
      font-size: 1.1em;
    }
  }
}