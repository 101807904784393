.item-list .item {
  padding: var(--inner-padding-r);
  border-radius: var(--corner-normal);
}

.unplaced-view {
  height: 100%;
  z-index: 1;
}

.split-view {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100svh;
  
  & > div:first-of-type {
    display: none;
  }
}