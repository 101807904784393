.list-table {
  --line: 1px solid var(--colour-line);
  height: 100%;
  position: relative;
  
  [data-dragging-over="true"] {
    //can style here if want
  }
  [data-is-dragging="true"] {
    border-top: var(--line);
    background-color: var(--colour-body);
  }
  [data-rbd-droppable-id] {
    min-height: calc(100% - 3em);
  }
  
  .absolute {
    position: absolute;
    width: 100%;
  }

  &.graphic {
    .table-item {
      grid-template-columns: 1fr max-content;
      padding: 0;
      grid-row-gap: 0;

      .thumb {
        width: 6rem;
        min-width: 6rem;
        height: 6rem;
      }

      .list-position {
        font-family: var(--hero-heading);
        font-size: 1.5em;
        padding-right: 0.5em;
      }

      & > .item-list {
        order: -1;
        padding: var(--inner-padding-m);

        & > div {
          min-height: 6rem;
        }
      }

      .table-controls {
        padding: var(--inner-padding-r);
      }

      &[data-hero="true"] {
        margin: 0;
        border-radius: 0;
        border: unset;
        border-bottom: var(--themed-border, unset);
        border-top: var(--themed-border, unset);
        
        font-size: 1.2em;

        .table-controls {
          font-size: 0.9em;
          padding-top: 0;
        }

        &[data-thumb="true"] > .item-list {
          .right-item-layout button {
            align-self: flex-start;
          }
        }

        & > .item-list {
          .item-row {
            flex-direction: column;
            align-items: flex-start;
            gap: var(--gap-m);

            .item-list {
              padding: var(--inner-padding-m);
              padding-right: 0;
            }
          }
        }
        
        .list-position {
          position: absolute;
          background: var(--colour-text);
          color: var(--colour-body);
          width: 7rem;
          height: 7rem;
          border-radius: 100%;
          z-index: 11;
          top: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 0;
          right: unset;
          left: clamp(1rem, calc(90% - 5.5rem), 30rem);
          padding-right: 0;
          border: var(--themed-border, unset);

          svg {
            margin-right: 6%;
            width: 40%;
            fill: var(--colour-body);
          }
        }

        &[data-thumb="false"] {
          .list-position {
            position: relative;
            width: 5rem;
            height: 5rem;
            top: unset;
            left: unset;
            margin-right: var(--inner-padding-m);
          }
        }

        button.empty {
          width: 90%;
          max-width: 26rem;
        }
        
        .thumb {
          width: 100%;
          min-width: 15.5rem;
          height: auto;
          aspect-ratio: 1/1;
        }
      }

      .table-item-info-container {
        grid-column: span 2;
      }
    }
  }
  
  .table-item {
    padding: var(--inner-padding-r);
    padding-left: var(--inner-padding-m);
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 0.5em auto;
    gap: var(--gap-r);
    align-items: center;
    position: relative;
    
    .floating-position {
      position: absolute;
      right: -0.25em;
      z-index: 1;
      background-color: var(--colour-highlight);
      width: 1.4rem;
      height: 1.4rem;
      border-radius: var(--round);
      display: grid;
      align-items: center;
      justify-content: center;
      color: var(--colour-text);
      // border: var(--line);
    }
    
    .table-item-info-container {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 500ms ease;
      grid-column: 2;
      
      &[aria-current="true"] {
        grid-template-rows: 1fr;
      }
      
      & > * {
        overflow: hidden;
      }
    }
    
    .table-controls {
      padding-bottom: var(--inner-padding-r);

      .tile {
        margin: 0 calc(var(--inner-padding-s) * -1);
      }
    }
    
    &[data-numbered="false"] {
      grid-template-columns: auto;
      
      .table-item-info-container {
        grid-column: unset;
      }
    }
    
    &[data-hero="true"] {
      background-color: var(--colour-highlight);
      margin: 0 var(--inner-padding-s);
      border-radius: var(--corner-normal);
      border-bottom: unset;
      border: var(--themed-border, unset);
      // padding-left: var(--inner-padding-r);
      
      font-size: 1.2em;

      .table-item-info-container {
        --colour-text: var(--colour-highlight-text);
      }

      & > .item-list {
        --colour-text: var(--colour-highlight-text);
      }
      
      .floating-position {
        background-color: var(--themed-text);
        color: var(--colour-body)
      }
      
      .thumb {
        width: 5.5rem;
        min-width: 5.5rem;
        height: 5.5rem;
      }
    }
    
    .table-item-extra {
      margin-left: var(--padding-m);
    }
  }
}

.share-preview {
  user-select: none;
  pointer-events: none;
  background-color: var(--colour-body);
  cursor: auto;

  transform-origin: top left;
  width: 50rem;

  .list-table.graphic {
    .table-item[data-hero="true"] {
      align-self: stretch;
      align-content: center;
      border-radius: var(--corner-normal) 0 0 var(--corner-normal);
      border-left: var(--themed-border, unset);
      flex: 0.9;

      & > .item-list {
        height: 100%;
      }
    }
    .item-row.no-gap {
      & > * {
        border: var(--line);
        flex: 1;

        .table-item:last-child {
          border: unset;
        }
      }
      & > :first-child {
        border-radius: var(--corner-normal) 0 0 var(--corner-normal);
        border-right: unset;
        &[data-hero="true"] {
          border: unset;
        }
      }
      & > :last-child {
        border-radius: 0 var(--corner-normal) var(--corner-normal) 0;
      }
    }
  }
}

@media screen and (min-width: 40em) {
  .list-table {
    &.graphic {
      .table-item {
        &[data-hero="true"] {
          border-radius: var(--corner-normal);
          border: var(--themed-border, unset);
          border-bottom: var(--themed-border, unset);
        }
      }
    }
  }
  .share-preview .list-table.graphic .table-item {
    &[data-hero="true"] {
      border-right: unset;
    }
  }
}