.full-height {
  height: 100%;
}
.height-80 {
  height: 80%;
}
.full-width {
  width: 100%;
}

*.min-width {
  min-width: 0;
}
*.min-height {
  min-height: 0;
}

*[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.3;
}
*[data-scroll="true"] {
  overflow-y: auto;
}
*[data-lock-scroll="true"] {
  overflow: hidden;
}
*[data-outline="true"] {
  outline: var(--line);
  outline-color: var(--colour-standout);
  outline-offset: 2px;
}
*.prevent-shrink {
  flex-shrink: 0;
}

*.position-absolute {
  position: absolute;
}
*.position-relative {
  position: relative;
}
*.sticky {
  position: sticky;
  top: 0px;
  z-index: 9;
  background-color: var(--colour-body);

  &.sticky-bottom {
    bottom: 0px;
  }
}

*[role="button"] {
  cursor: pointer;
}

.bottom-border {
  border-bottom: var(--line);
  border-color: var(--colour-line);
  
  &.dotted {
    border-bottom-style: dotted;
    border-block-width: 3px;
  }
}
.left-border {
  border-left: var(--line);
  border-color: var(--colour-line);
}
.right-border {
  border-right: var(--line);
  border-color: var(--colour-line);
}
.secondary-border {
  border-color: var(--colour-subtle);
}
.top-border {
  border-top: var(--line);
  border-color: var(--colour-line);
}
.secondary-border {
  border-color: var(--colour-subtle);
}

.divider-line {
  &-vertical {
    width: 1px;
    margin: 0.5rem 0;
    align-self: stretch;
    background-color: var(--colour-text-secondary);
    position: relative;
    flex-shrink: 0;
  }
  &-horizontal {
    width: 100%;
    height: 1px;
    background-color: var(--colour-text-secondary);
    position: relative;
    flex-shrink: 0;
  }
  &-vertical.or::after,
  &-horizontal.or::after {
    content: 'OR';
    font-weight: bolder;
    font-size: 0.7em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: var(--colour-text-secondary);
    background-color: var(--colour-body);
    padding: var(--inner-padding-xs);
    border-radius: var(--corner-xsmall);
  }
}

.tile {
  background-color: var(--colour-subtle);
  border-radius: var(--corner-normal);
  
  &.themed {
    --colour-body: var(--themed-text);
    --colour-text: var(--themed-body);
    background-color: var(--colour-body);
  }
  &.overlay {
    background-color: var(--themed-overlay, var(--colour-overlay-light));
  }
  
  &.outline {
    border: var(--line);
  }
  &.round {
    border-radius: 10em;
  }

  &[data-alert="true"] {
    background-color: var(--colour-error);
  }
  &[data-warn="true"] {
    background-color: var(--colour-warn);
  }
  
  &[data-small="true"] {
    border-radius: var(--corner-xsmall);
  }
}

.icon-display {
  display: flex;
  
  &.small {
    svg {
      width: 1.1rem;
    }
  }
  &.large {
    svg {
      width: 2rem;
    }
  }
  
  svg {
    width: 1.4rem;
  }
  
  &.secondary svg {
    fill: var(--colour-text-secondary);
  }
}

.theme-indicator {
  --indicator-size-mult: 1;
  width: calc(2em * var(--indicator-size-mult));
  height: calc(2em * var(--indicator-size-mult));
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .theme-highlight {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 2;
  }
  .theme-dot {
    position: absolute;
    width: calc(0.9em * var(--indicator-size-mult));
    height: calc(0.9em * var(--indicator-size-mult));
    border-radius: 100%;
    z-index: 3;
  }
}

.icon {
  display: flex;
  
  &.small {
    svg {
      width: 1.3rem;
    }
  }
  &.xsmall {
    svg {
      width: 0.9rem;
    }
  }
  &.secondary {
    opacity: 0.6;
  }
}

.banner {
  width: 100%;
  height: 12rem;
  position: relative;
  border-bottom: var(--line);
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
  
  .banner-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
}

.thumb {
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  border-radius: var(--corner-xsmall);
  overflow: hidden;
  
  &.small {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
  }
  &.large {
    width: 4.5rem;
    height: 4.5rem;
    min-width: 4.5rem;
    min-height: 4.5rem;
  }
  &.xl {
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
  }
  &.full {
    width: 100%;
    max-height: 90vh;
    max-width: 40em;
    height: auto;

    &.square {
      aspect-ratio: 1/1;
    }

    &.contain {

      width: auto;
      display: flex;
      justify-content: center;

      img {
        object-fit: contain;
        border-radius: inherit;
      }
    }
  }
  &.full-width {
    width: 100%;
    height: 100%;
    
    &.contain {

      width: auto;
      display: flex;
      justify-content: center;

      img {
        object-fit: contain;
        border-radius: inherit;
      }
    }
  }
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

img.pretty {
  height: auto;
  max-width: 100%;
  object-fit: contain;
  border-radius: var(--corner-xsmall);
  max-height: 100%;
  width: auto;
}

.floating-banner-image {
  --avatar-size: 5rem;
  height: var(--avatar-size);
  width: 100%;
  border-radius: var(--corner-normal);
  overflow: hidden;
  border: var(--line);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user-avatar {
  --avatar-size: 3rem;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: var(--round);
  border: var(--line);
  overflow: hidden;
  flex-shrink: 0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &.large {
    --avatar-size: 7rem;
  }
  &.med {
    --avatar-size: 5rem;
  }
  &.small {
    --avatar-size: 2.4rem;
  }
  &.xsmall {
    --avatar-size: 2rem;
  }
}

.coming-soon {
  margin-top: 20vh;
  & > div {
    height: 10rem;
    background-color: var(--colour-element);
    --colour-text: var(--colour-element-text);
    padding: var(--inner-padding-r);
    border-radius: var(--corner-normal);
  }
}

.logo svg {
  width: 10rem;
}