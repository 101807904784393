.tag {
  background-color: var(--colour-element);
  --colour-text: var(--colour-element-text);
  padding: var(--inner-padding-s) var(--inner-padding-r);
  border-radius: var(--corner-normal);
  
  &.prevent-shrink {
    flex-shrink: 0;
  }
  
  &[data-white="true"] {
    background-color: var(--colour-white);
    --colour-text: var(--colour-black);
  }
  &[data-themed="true"] {
    background-color: var(--colour-highlight);
    --colour-text: var(--colour-highlight-text);
    border: var(--themed-border, unset);
  }
  &[data-small="true"] {
    padding: var(--inner-padding-xs) var(--inner-padding-m);
  }
  &[data-secondary="true"] {
    background-color: transparent;
    border: var(--line);
    --colour-text: var(--colour-text);
    
    &[data-themed="true"] {
      
      --colour-text: var(--themed-text);
      border: solid 1px var(--themed-text);
    }
  }
}