.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: var(--inner-padding-s);
  padding-right: var(--inner-padding-s);
  z-index: var(--z-modal, 10);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(0,0%,0%,0.25);
  backdrop-filter: blur(2px);
  
  .modal {
    background-color: var(--colour-body);
    width: 100%;
    max-width: 50rem;
    border-radius: var(--corner-normal);
    border: var(--line);
    display: grid;
    grid-template-rows: clamp(20em, 80vh, 45em);
    overflow: hidden;
    
    & > .item-list {
      height: fit-content;
    }
    
    &[data-scroll="true"] {
      overflow-y: auto;
    }
    
    &.black {
      background-color: var(--colour-element-blackout-constant);
      --colour-text: var(--colour-element-text-blackout-constant);
      --colour-subtle: var(--colour-element-subtle-blackout-constant);
      --colour-element: var(--colour-element-text-blackout-constant);
      --colour-element-text: var(--colour-element-blackout-constant);
      --colour-body: var(--colour-element-text-blackout-constant);
      --colour-line: var(--colour-element-text-blackout-constant);
      --line: 1px solid var(--colour-line);
    }

    &.small {
      max-width: 30rem;
      grid-template-rows: clamp(15em, 80vh, 30em);
    }
  }
  
  &[data-fixed-bottom-item="true"] {
    .modal > .bottom-item-layout {
      overflow: hidden;
      
      & > :first-child {
        overflow: auto;
      }
    }
  }
  
  &[data-bottom-pane="true"] {
    align-items: flex-end;
    padding-left: calc(var(--inner-padding-xs) / 1);
    padding-right: calc(var(--inner-padding-xs) / 1);
    padding-bottom: calc(var(--inner-padding-xs) / 1);
    
    .modal {
      padding: var(--inner-padding-s);
      grid-template-rows: auto;
      border-color: var(--colour-overlay);
      border-width: 1px;
    }
    &[data-fixed-bottom-item="true"] {
      .modal {
        padding: unset;
      }
    }
  }
  
  &[data-full-height-bottom-pane="true"] {
    .modal {
      height: 90vh;
    }
  }
  
  &[data-fullscreen="true"] {
    left: 0;
    right: 0;
    padding: 0;
    
    .modal {
      height: 100%;
      border-radius: unset;
      border: unset;
      grid-template-rows: auto;
      max-width: unset;
      justify-items: center;
      
      & > * {
        max-width: 40em;
        width: 100%;
      }
    }
  }
  
  &[data-remove-padding="true"] {
    .modal {
      padding: unset;
    }
  }
}

@media screen and (min-width: 40em) {
  .modal-container {
    &[data-full-height-bottom-pane="true"] {
      .modal {
        height: 70vh;
      }
    }
  }
}