#toast-root {
  position: fixed;
  z-index: var(--z-toast);
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-items: center;
}

.toast {
  // position: fixed;
  background-color: var(--colour-subtle);
  color: var(--colour-text);

  width: calc(100% - calc(var(--inner-padding-r) * 2));
  max-width: 60em;

  padding: var(--inner-padding-r);
  margin: var(--inner-padding-r);
  margin-bottom: 0;
  border-radius: var(--corner-small);
  
  svg {
    fill: car(--colour-text);
  }

  &[data-error='true'] {
    background-color: var(--colour-error);
    --colour-text: var(--colour-white);
  }
  &[data-success='true'] {
    background-color: var(--colour-success);
    --colour-text: var(--colour-white);
  }
  &[data-warn='true'] {
    background-color: var(--colour-warn);
    --colour-text: var(--colour-white);
  }
}
