// @use '../abstracts/breakpoints' as br;

:root {
  --colour-white: white;
  --colour-black: black;
  --colour-redorange: hsl(7, 91%, 67%);
  
  --colour-error: hsl(0, 50%, 50%);
  --colour-warn: hsl(38, 60%, 51%);
  --colour-success: hsl(100, 36%, 47%);
  
  --themed-body: unset;
  --themed-highlight: unset;
  --themed-text: unset;
  --themed-text-highlight: unset;

  --colour-body: var(--colour-body-base);
  --colour-text: var(--colour-text-base);
  --colour-text-secondary: var(--colour-text-secondary-base);
  --colour-element: var(--colour-element-base);
  --colour-element-text: var(--colour-element-text-base);
  --colour-element-subtle: var(--colour-element-subtle-base);
  --colour-subtle: var(--colour-subtle-base);
  --colour-highlight: var(--colour-element);
  --colour-highlight-text: var(--colour-element-text);
  --colour-line: var(--colour-text-base);
  --colour-standout: var(--colour-redorange);
  
  --colour-hover: unset;
  --colour-overlay: unset;
  --colour-overlay-light: unset;
  --colour-overlay-dark: unset;
  --colour-disabled: unset;

  --filter-brightness: 0.85;
  --opacity-hover: 0.6;

  --topbar-height: 3.5rem;
  --topbar-opacity: 1;
  --bottombar-height: 3.5rem;

  --dark-grad-overlay: linear-gradient(transparent, rgba(0, 0, 0, 0.75));

  --line: solid 1px var(--colour-line);

  --round: 100%;
  --corner-full: 10rem;
  --corner-normal: 1.1rem;
  --corner-small: 0.7rem;
  --corner-xsmall: 0.6rem;
  
  --colour-transition-timing: 0.7s;
  --background-colour-transition: background-color var(--colour-transition-timing) ease;
  
  --unplaced-height: 40vh;
  --main-bottom-padding-small: calc(var(--bottombar-height) + var(--inner-padding-m));
  --main-bottom-padding-large: calc(var(--unplaced-height) + var(--main-bottom-padding-small));

  /* z-index */
  --z-toast: 3000;
  --z-dropdown: 2001;
  --z-modal: 2000;
  --z-global-nav: 1000;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  
  scrollbar-width: thin;
  scrollbar-color: var(--colour-disabled) transparent;
}

*[data-themed="true"] {
  --colour-body: var(--themed-body);
  --colour-text: var(--themed-text);
  --colour-highlight-text: var(--themed-text-highlight);
  --colour-highlight: var(--themed-highlight);
  --colour-element: var(--themed-text);
  --colour-element-text: var(--themed-body);
  --colour-line: var(--themed-line);
  --line: 2px solid var(--colour-line);
  --colour-standout: var(--themed-highlight);
  --colour-subtle: var(--themed-overlay);
}

html,
body,
.root-inner,
#root {
  background-color: var(--colour-body);
  transition: var(--background-colour-transition);
  min-width: 0;
  scrollbar-width: auto;
}

//caused some weirdness
// *[data-themed="true"] {
//   transition: var(--background-colour-transition);
// }

.root-inner {
  min-height: 100vh;
  min-height: 100svh;
  padding-top: var(--topbar-height);
  display: grid;
}

main {
  height: 100%;
  width: 100%;
  color: var(--colour-text);
  max-width: 40em;
  margin: 0 auto;
  min-width: 0;
  
  &[data-padding='true'] {
    --local-padding-value: var(--inner-padding-m);
    padding: var(--inner-padding-m);
  }
  
  &[data-nav='true'] {
    padding-bottom: var(--main-bottom-padding-small);
  }

  &[data-fixed-height='true'] {
    height: calc(100vh - var(--topbar-height));
    height: calc(100svh - var(--topbar-height));
  }
}

svg {
  width: 2rem;
  fill: var(--colour-text);
}

ul {
  list-style: none;

  &.dotted {
    list-style: outside;
    & > * {
      padding-bottom: var(--gap-s);
    }
  }
}

.always-black {
  --colour-body: var(--colour-black);
  --colour-text: var(--colour-white);
  --colour-subtle: var(--colour-dark-grey);
}
.always-white {
  --colour-text: var(--colour-black);
  --colour-body: var(--colour-white);
}

// the max width of main
@media screen and (min-width: 40em) {
  main {
    &[data-outline-body="true"] {
      border-right: var(--line);
      border-left: var(--line);
      border-color: var(--colour-subtle);
      border-width: 1px; 
    }
  }
  .header-bar {
    border-bottom: var(--line);
  }
}

// @media screen and (max-height: br.$height-l) {
// }

// @media screen and (max-width: br.$width-xl) {
// }

// @media screen and (max-width: br.$width-l) {

// }

// @media screen and (max-width: br.$width-m) {

// }

// @media screen and (max-width: br.$width-s) {

// }

// @media screen and (min-width: br.$width-xxl) {

// }

// @media screen and (max-height: br.$height-m) {
// }

// @media screen and (max-height: br.$height-s) {

// }
