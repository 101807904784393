.bottom-floating {
  
  --colour-text: var(--colour-element-text-blackout-constant);
  --colour-subtle: var(--colour-element-subtle-blackout-constant);
  --colour-element: var(--colour-element-text-blackout-constant);
  --colour-element-text: var(--colour-element-blackout-constant);
  --colour-line: var(--colour-element-text-blackout-constant);
  --line: 1px solid var(--colour-line);
  
  position: fixed;
  bottom: calc(var(--inner-padding-xs) / 2);
  left: calc(var(--inner-padding-xs) / 2);
  right: calc(var(--inner-padding-xs) / 2);
  height: var(--bottombar-height);
  
  & > div {
    background-color: var(--colour-element-blackout-constant);
    padding: var(--inner-padding-xs) var(--inner-padding-s);
    border-radius: var(--corner-small);
    max-width: 30em;
    margin: 0 auto;
    height: 100%;
  }
  
  .main-nav-layout {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    
    & > :last-child {
      justify-content: flex-end;
    }
  }
  
  &[data-flexible="true"] {
    height: auto;
  }
  
  // button.icon-text {
  //   --colour-element: ;
  //   --colour-text: var(--colour-element-base);
  // }
  
  a {
    display: contents;
    
    button {
      position: relative;
    }
    
    &[data-active="true"] button::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 0.8rem;
      height: 0.14rem;
      border-radius: var(--corner-small);
      background-color: var(--colour-text-secondary);
      bottom: -0.18rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.header-bar {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: var(--topbar-height);
  opacity: var(--topbar-opacity);
  padding: 0 var(--inner-padding-s);
  padding-left: var(--inner-padding-r);
  display: grid;
  background-color: var(--colour-body);
  transition: var(--background-colour-transition);
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease, background-color var(--colour-transition-timing) ease;
  z-index: 100;
  
  svg {
    height: 1.3rem;
    width: auto;
  }
  
  h2 {
    font-size: 1.5em;
  }
}