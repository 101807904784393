// @use '../abstracts/breakpoints' as br;

*[data-padding="true"] {
  padding: var(--padding-s);
  --local-padding-value: var(--padding-s);
}
*[data-padding-small="true"] {
  padding: var(--inner-padding-m);
  --local-padding-value: var(--inner-padding-m);
}

.padding {
  padding: var(--padding-s);
  --local-padding-value: var(--padding-s);
  
  &-horizontal {
    padding: var(--padding-s);
    padding-top: 0;
    padding-bottom: 0;
  }
  &-vertical {
    padding: var(--padding-s);
    padding-left: 0;
    padding-right: 0;
  }
  
  &-large {
    padding: var(--padding-r);
    --local-padding-value: var(--padding-r);
    
    &-horizontal {
      padding: var(--padding-r);
      padding-top: 0;
      padding-bottom: 0;
    }
    &-vertical {
      padding: var(--padding-r);
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-small {
    padding: var(--inner-padding-m);
    --local-padding-value: var(--inner-padding-m);
    
    &-horizontal {
      padding: var(--inner-padding-m);
      padding-top: 0;
      padding-bottom: 0;
    }
    &-vertical {
      padding: var(--inner-padding-m);
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-xs {
    padding: var(--inner-padding-s);
    --local-padding-value: var(--inner-padding-s);
    
    &-horizontal {
      padding: var(--inner-padding-s);
      padding-top: 0;
      padding-bottom: 0;
    }
    &-vertical {
      padding: var(--inner-padding-s);
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.padding-bottom {
  --local-padding-value: var(--inner-padding-r);
  padding-bottom: var(--inner-padding-r);

  &-small {
    --local-padding-value: var(--inner-padding-s);
    padding-bottom: var(--inner-padding-s);
  }
  &-large {
    --local-padding-value: var(--inner-padding-xxl);
    padding-bottom: var(--inner-padding-xxl);
  }
}
.padding-top {
  --local-padding-value: var(--inner-padding-r);
  padding-top: var(--inner-padding-r);

  &-large {
    --local-padding-value: var(--padding-m);
    padding-top: var(--padding-m);
  }
  &-small {
    --local-padding-value: var(--inner-padding-s);
    padding-top: var(--inner-padding-s);
  }
}
.padding-left {
  --local-padding-value: var(--inner-padding-r);
  padding-left: var(--inner-padding-r);

  &-xl {
    --local-padding-value: var(--inner-padding-xxl);
    padding-left: var(--inner-padding-xxl);
  }
  &-large {
    --local-padding-value: var(--padding-m);
    padding-left: var(--padding-m);
  }
  &-small {
    --local-padding-value: var(--inner-padding-m);
    padding-left: var(--inner-padding-m);
  }
}
.padding-right {
  --local-padding-value: var(--inner-padding-r);
  padding-right: var(--inner-padding-r);

  &-xl {
    --local-padding-value: var(--inner-padding-xxl);
    padding-right: var(--inner-padding-xxl);
  }
  &-large {
    --local-padding-value: var(--padding-m);
    padding-right: var(--padding-m);
  }
  &-small {
    --local-padding-value: var(--inner-padding-m);
    padding-right: var(--inner-padding-m);
  }
}

.remove-padding-bottom {
  padding-bottom: 0;
}
.remove-padding-top {
  padding-top: 0;
}

.align-content-start {
  align-content: flex-start;
}

.escape-container {
  width: calc(100% + calc(var(--local-padding-value) * 2));
  margin: 0 calc(var(--local-padding-value) * -1);
}

.horizontal-scroll-container {
  overflow-x: auto;
  padding-bottom: var(--inner-padding-m);
  margin-bottom: calc(var(--inner-padding-m) * -1);
  // display: flex;

  // &.end-spacer > .item-row {
  //   margin-right: var(--padding-r);
  // }
}

.slider {
  width: calc(100% + calc(var(--local-padding-value) * 2));
  overflow-x: auto;
  margin: 0 calc(var(--local-padding-value) * -1);
  
  .slider-inner {
    --slider-card-padding: var(--inner-padding-m);
    padding-left: var(--slider-card-padding);
    display: flex;
    flex-direction: row;
    gap: var(--slider-card-padding);
    
    .card-outer {
      width: 80%;
      flex-shrink: 0;
      max-width: 24rem;
      
      &:first-child:last-child {
        width: calc(100% - var(--slider-card-padding));
      }
    }
    .slider-end-block {
      width: 1px;
      flex-shrink: 0;
    }
  }
}

.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);
  
  &.center-horizontal {
    align-items: center;
  }
  &.center-vertical {
    justify-content: center;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.full-height {
    height: 100%;
  }
  &.align-start {
    align-items: flex-start;
  }
}

.item-row {
  display: flex;
  flex-direction: row;
  gap: var(--gap-m);
  align-items: center;

  &.wrap {
    flex-wrap: wrap;
  }
  &.center-horizontal {
    justify-content: center;
  }
  &.full-height {
    height: 100%;
  }
  &.allow-truncate {
    min-width: 0;
  }
  &.align-top {
    align-items: flex-start;
  }
  &.center-spread {
    justify-content: space-evenly;
  }
  &.justify-end {
    justify-self: flex-end;
  }
}

.item-grid-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
  gap: var(--gap-r);

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(8em, 1fr));
  }
}

.item-row.small,
.item-list.small,
.item-row-wrap.small {
  gap: var(--gap-xs);
}
.item-row.med,
.item-list.med {
  gap: var(--gap-s);
}

.item-row.large,
.item-list.large {
  gap: var(--gap-r);
}
.item-row.xl,
.item-list.xl {
  gap: var(--gap-l);
}

.item-row.xxl,
.item-list.xxl {
  gap: var(--gap-xxl);
}

.item-row.no-gap,
.item-list.no-gap {
  gap: unset;
}


.bottom-item-layout {
  display: grid;
  grid-template-rows: auto max-content;
  gap: var(--gap-r);
  height: 100%;

  &.center-vertical {
    justify-items: center;
  }
}
.top-item-layout {
  display: grid;
  grid-template-rows: max-content auto;
  gap: var(--gap-r);
  height: 100%;
}

.right-item-layout,
.left-item-layout {
  display: grid;
  grid-template-columns: auto max-content;
  gap: var(--gap-r);

  button {
    align-self: center;
  }
  &.wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > :first-child {
      flex: 1;
    }
  }
  &.center-horizontal {
    align-items: center;
  }
  &.center-vertical {
    justify-content: center;
  }
  
  &.justify-end {
    justify-items: flex-end;
  }
  &.justify-start {
    justify-items: flex-start;
  }
  &.align-start {
    align-items: flex-start;
  }
}

.left-item-layout {
  grid-template-columns: max-content auto;
}

.top-item-layout,
.bottom-item-layout,
.left-item-layout,
.right-item-layout {
  &.no-gap {
    gap: 0;
  }
  &.small {
    gap: var(--gap-s);
  }
  &.large {
    gap: var(--gap-l);
  }
  &.xl {
    gap: var(--gap-xl);
  }
  &.xxl {
    gap: var(--gap-xxl);
  }
}

// @media screen and (max-height: br.$height-s) {
//   .subtitle-section > :first-child {
//     font-size: 0.9em;
//   }
// }

// @media screen and (min-width: br.$width-xxl) {
//   .item-grid-wrap {
//     grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
//   }
// }

// @media screen and (max-width: br.$width-xl) {
//   .item-row.collapse-l {
//     flex-wrap: wrap;

//     & > :first-child {
//       width: 100%;
//       flex-shrink: 0;
//     }
//   }
//   .right-item-layout.collapse-xl {
//     grid-template-columns: auto;
//   }
// }
// @media screen and (max-width: br.$width-l) {
//   .item-row.collapse-l {
//     flex-wrap: wrap;

//     & > :first-child {
//       width: 100%;
//       flex-shrink: 0;
//     }
//   }
//   .right-item-layout.collapse-l {
//     grid-template-columns: auto;
//   }
// }
// @media screen and (max-width: br.$width-m) {
//   .item-row {
//     &.collapse-s-two-pane {
//       display: grid;
//       grid-template-columns: 1fr 1fr 1fr;
//     }
//   }
//   .right-item-layout.collapse-m {
//     grid-template-columns: auto;
//   }
// }
// @media screen and (max-width: br.$width-s) {
//   .item-row {
//     &.collapse-s {
//       flex-wrap: wrap;

//       & > :first-child {
//         width: 100%;
//         flex-shrink: 0;
//       }
//     }
//     &.collapse-s-two-pane {
//       display: grid;
//       grid-template-columns: 1fr 1fr;
//     }
//   }
//   .right-item-layout.collapse-s {
//     grid-template-columns: auto;
//   }
// }
// @media screen and (max-width: br.$width-xxs) {
//   .item-row {
//     &.collapse-s-two-pane {
//       grid-template-columns: auto;
//     }
//   }
// }
