@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@500&family=Inter:wght@300;400&family=Poppins:wght@400;600&family=Modak&display=swap');

:root {
  --hero-heading: 'Hanken Grotesk', sans-serif;
  --heading: 'Poppins', sans-serif;
  --subheading: 'Poppins', sans-serif;
  --content: 'Inter', sans-serif;

  --bold: 600;
  --semi-bold: 500;
  --regular: 400;
  --light: 300;
  --linespace: 1;
}

::placeholder {
  color: var(--colour-text);
}

html {
  font: var(--regular) 1em / var(--linespace) var(--content);
}
textarea,
input {
  font: var(--regular) 1em / 1.3 var(--content);
}

.body {
  font: var(--regular) 1em / var(--linespace) var(--content);
  
  span {
    font: var(--bold) 1em / var(--linespace) var(--content);
  }
}

.heading-large {
  font: var(--bold) 1.75em/ var(--linespace) var(--heading);
  
  &.hero {
    font: var(--semi-bold) 1.75em/ var(--linespace) var(--hero-heading);
  }
}

.heading-medium {
  font: var(--bold) 1.3em/ var(--linespace) var(--heading);
}

.heading-small {
  font: var(--bold) 1.1em / var(--linespace) var(--subheading);
}

.subtitle-large {
  font: var(--regular) 0.95em / var(--linespace) var(--subheading);
}

.subtitle-medium,
label {
  font: var(--semi-bold) 0.8em / var(--linespace) var(--subheading);
}

.subtitle-small {
  font: 700 0.75em / var(--linespace) var(--subheading);
}

.heading-large,
.heading-medium,
.heading-small,
.subtitle-large,
.subtitle-medium,
.subtitle-small,
.body,
span {
  
  color: var(--colour-text);
  
  &.highlight {
    --colour-text: var(--colour-highlight);
  }
  &.secondary {
    opacity: 0.6;
  }
  &.weight-light {
    font-weight: var(--regular);
  }
  &.weight-bold {
    font-weight: bolder;
  }
  &.center {
    text-align: center;
  }
  &.header {
    text-transform: capitalize;
  }
  &.spaced {
    line-height: 1.3;
  }
  &.indent {
    margin-left: var(--gap-s);
  }
  &.break-anywhere {
    word-wrap: anywhere;
  }
}

.first-letter-cap {
  text-transform: unset;
  &::first-letter {
    text-transform: uppercase;
  }
}

*.cap {
  text-transform: uppercase;
}

.quote {
  font-style: oblique;
}
.ellipsis {
  text-overflow: '..';
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3;
}
.ellipsis-block {
  --clamp-amount: 2;

  display: -webkit-box;
  -webkit-line-clamp: var(--clamp-amount);
  -webkit-box-orient: vertical;
  overflow: hidden;
  //without this line height, descenders get cut off
  line-height: 1.25;
  // max height should be clamp val * text line height
  max-height: calc(var(--linespace) * var(--clamp-amount));
  word-break: break-word;

  &[data-clamp='3'] {
    --clamp-amount: 3;
  }
}

label[aria-required="true"] {
  &::after {
    content: " *";
  }
}