input,
textarea {
  color: var(--colour-text);
}

.text-input {
  width: 100%;
  display: grid;
  grid-template-rows: min-content auto;
  gap: var(--gap-s);
  
  &[data-label="false"] {
    grid-template-rows: auto;
  }
  
  input,
  textarea {
    appearance: none;
    border: var(--line);
    border-radius: var(--corner-small);
    padding: var(--inner-padding-m);
    outline: unset;
    background-color: unset;
    width: 100%;
    
    &:active,
    &:focus {
      border-color: var(--colour-standout);
    }
  }
  
  textarea {
    resize: vertical;
    min-height: 4rem;
    max-height: 9rem;
  }
  
  &.secondary {
    input,
    textarea {
      border: unset;
      background-color: var(--colour-subtle);
      
      &:active,
      &:focus {
        box-shadow: 0 0 0 2px var(--colour-standout);
      }
    }
  }
  
  label {
    margin-left: var(--gap-s);
    text-transform: capitalize;
  }
  
  &[aria-required="true"] {
    label::after {
      content: " *";
    }
  }
}
input.file-input {
  display: none;
}

input.droppable[type='file'] {
  display: none;
}

label.file-input-large {
  background-color: var(--colour-subtle);
  padding: var(--inner-padding-r);
  border-radius: var(--corner-small);
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: var(--gap-m);
  border: var(--line);
  border-width: 1px;
  border-color: var(--colour-disabled);
  border-style: dashed;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
  &[data-drag-over='true'] {
    border-style: solid;
  }
}

label.file-input {
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
  align-self: center;
  position: relative;
  
  &.secondary {
    background-color: var(--colour-subtle);
    padding: var(--inner-padding-m);
    border-radius: var(--corner-small);
  }
  
  .floating-indicator {
    position: absolute;
    
    svg {
      width: 1.75rem;
    }
  }
}

.select-container, .select-__menu-portal {
  font-family: var(--subheading);
  text-transform: capitalize;
  min-width: 10em;
  width: 100%;
  z-index: var(--z-dropdown) !important;

  &.fit-width {
    min-width: 8em;
    width: auto;
  }

  &.bright {
    &.has-value {
      .select-__control {
        background-color: var(--colour-element);
        color: var(--colour-element-text);
      }
      .select-__single-value {
        color: var(--colour-element-text);
      }
      svg {
        fill: var(--colour-element-text);
      }
  
      .select-__placeholder {
        color: var(--colour-element-text);
      }
    }

    .select-__placeholder {
      color: var(--colour-text);
    }
  }

  .select-__placeholder {
    color: var(--colour-text-secondary);
  }

  .select-error {
    outline: 2px solid red;
    border-radius: var(--corner-small);
  }

  .select-__single-value {
    color: var(--colour-text);
    line-height: 1.5;
  }

  .select-__control {
    border: var(--line);
    background-color: transparent;
    border-radius: var(--corner-small);
    font-size: 0.9em;
    color: var(--colour-text);
    padding: calc(var(--inner-padding-m) / 3);
    cursor: pointer;
  }

  svg {
    fill: var(--colour-text);
  }

  .select-__control--is-focused {
    box-shadow: 0 0 0 1px var(--colour-standout);
  }

  .select-__indicator-separator {
    display: none;
  }

  .select-__menu {
    border: var(--line);
    border-radius: var(--corner-small);
    overflow: hidden;
    background-color: var(--colour-body);
    z-index: 2;
  }

  .select-__menu-list {
    padding: 0;
    margin: 0;
  }

  .select-__option {
    color: var(--colour-text);
    padding: var(--inner-padding-r);
  }

  .select-__option--is-focused {
    background-color: var(--colour-subtle);
    cursor: pointer;
    color: var(--colour-text);
  }

  .select-__option--is-selected {
    background-color: var(--colour-element);
    color: var(--colour-element-text);
  }
  .select-__input-container {
    color: var(--colour-text);
  }
}

.switch-input {
  position: relative;
  display: grid;
  width: 3.5rem;
  height: 2rem;
  border-radius: 1.5rem;
  border: var(--line);
  
  input {
    opacity: 0;
    width: 0;
    height: 0;
    
    &:checked + .switch-knob {
      transform: translateX(100%);
    }
    &:focus + .switch-knob::before {
      outline: var(--line);
      outline-color: var(--colour-standout);
    }
    &:checked + .switch-knob::before {
      background-color: var(--colour-standout);
    }
  }
  
  .switch-knob {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0.2rem;
    right: 0.2rem;
    width: calc(calc(100% - 0.4rem) / 2);
    height: 100%;
    transition: 0.4s;
    display: grid;
    align-items: center;
    justify-content: center;
    
    &::before {
      content: '';
      position: absolute;
      height: 80%;
      aspect-ratio: 1/1;
      background-color: var(--colour-text);
      border-radius: var(--round);
      justify-self: center;
      transition: background-color 0.4s;
    }
  }
}

input[type='checkbox'] {
  border: unset;
  background-color: transparent;
  border: var(--line);
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.35rem;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &::after {
    content: '\2713';
    z-index: 1;
    color: var(--colour-element-text);
    font-size: 1.4em;
    font-weight: 800;
    line-height: 0;
    opacity: 0;
  }
  &:checked {
    background-color: var(--colour-element);
    &::after {
      opacity: 1;
    }
  }
}