nav.tabs {
  
  &[data-unstyled="true"] {
    ul {
      padding: unset;
    }
  }
  
  ul {
    padding: var(--inner-padding-r);
    padding-left: var(--padding-s);
    padding-right: 0;
    overflow-x: auto;

    li {
      text-wrap: nowrap;
    }
  }
  &[data-right-element="true"] {
    // padding-right: var(--inner-padding-m);
  }
  &[data-sticky="true"] {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: var(--colour-body);
    // transition: var(--background-colour-transition);
  }
}