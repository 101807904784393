.loading-ring {
  position: relative;
  .inner-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.lds-ring {
  
  --loading-size-mult: 1.5;
  --loading-colour: var(--colour-text);
  
  &[data-small="true"] {
    --loading-size-mult: 2;
  }
  
  display: inline-block;
  position: relative;
  width: calc(64px / var(--loading-size-mult));
  height: calc(64px / var(--loading-size-mult));
  
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(52px / var(--loading-size-mult));
    height: calc(52px / var(--loading-size-mult));
    margin: calc(6px / var(--loading-size-mult));
    border: calc(6px / var(--loading-size-mult)) solid var(--loading-colour);
    border-radius: 50%;
    animation: lds-ring 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--loading-colour) transparent transparent transparent;
    
    &:nth-child(1) {
      animation-delay: -0.675s;
    }
    &:nth-child(2) {
      animation-delay: -0.45s;
    }
    &:nth-child(3) {
      animation-delay: -0.225s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}