a.button,
button {

  background-color: transparent;
  outline: none;
  border: none;
  text-align: left;
  font: var(--regular) 1em / var(--linespace) var(--subheading);
  
  &:not(.empty) {
    border-radius: var(--corner-full);
    padding: calc(var(--inner-padding-m) * 1.2) var(--inner-padding-r);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colour-text);
    white-space: nowrap;
    
    svg {
      fill: var(--colour-element-text);
    }
  }
  
  &.short {
    padding: var(--inner-padding-s) var(--inner-padding-r);
  }
  &.small {
    svg {
      width: 1.2rem;
    }
  }
  &.tiny {
    padding: var(--inner-padding-xs) var(--inner-padding-s);
    font-size: 0.8em;
    
    svg {
      width: 0.7rem;
    }
  }
  
  svg {
    width: 1.5rem;
  }

  &.primary {
    background-color: var(--colour-element);
    --colour-text: var(--colour-element-text);
    
    &[aria-disabled="true"] {
      background-color: var(--colour-disabled);
    }
  }
  
  &.filter {
    padding: var(--inner-padding-m) var(--inner-padding-r);
    white-space: nowrap;

    &.small {
      padding: var(--inner-padding-s) var(--inner-padding-m);
    }
    
    &[aria-selected="true"] {
      background-color: var(--colour-element-subtle);
      color: var(--colour-element-text);
    }
  }
  &.secondary {
    background-color: transparent;
    border: var(--line);
    color: var(--colour-text);
    
    svg {
      fill: var(--colour-text);
    }
    
    &.icon {
      border: unset;
    }
  }
  &.subtle {
    background-color: var(--colour-subtle);
    color: var(--colour-element);
    
    &[aria-disabled="true"] {
      background-color: var(--colour-disabled);
    }
    
    svg {
      fill: var(--colour-element);
    }
  }
  &.alert,
  &[data-alert="true"] {
    background-color: var(--colour-standout);
    color: var(--colour-white);
    
    svg {
      fill: var(--colour-white);
    }
  }
  
  &.icon-text {
    display: flex;
    flex-direction: row;
    gap: var(--gap-m);
    align-items: center;
    white-space: unset;
    
    svg {
      width: 1.3rem;
      flex-shrink: 0;
    }
    
    &.large {
      svg {
        width: 1.5rem;
      }
    }
    &.small {
      svg {
        width: 1.1rem;
      }
    }
    &.xs {
      svg {
        width: 0.9rem;
      }
    }
    &.xxs {
      svg {
        width: 0.7rem;
      }
    }
  }
  
  &.outline {
    border: var(--line);
  }
  
  &.icon {
    background-color: transparent;
    padding: var(--inner-padding-xs);
    position: relative;
    
    .theme-indicator {
      --indicator-size-mult: 0.8;
    }
    
    
    svg {
      fill: var(--colour-text);
    }
    
    &.small {
      svg {
        width: 1.3rem;
      }
    }
    &.xs {
      padding: var(--inner-padding-s);
      svg {
        width: 1rem;
      }
    }
    
    &[data-highlight="true"] {
      svg {
        fill: var(--colour-standout);
      }
    }
    
    &.background {
      border-radius: var(--corner-xsmall);
      padding: var(--inner-padding-s);
      background-color: var(--colour-subtle);
      
      &.confirm {
        background-color: hsla(112, 36%, 49%, 30%);
      }
      &.cancel {
        background-color: hsla(3, 60%, 49%, 30%);
      }
    }
    
    &.always-white {
      svg {
        fill: var(--colour-white);
      }
    }
    
    &.shadow {
      filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.6));
    }
    
    &.escape-padding {
      margin: calc(var(--inner-padding-xs) * -1);
    }
    
    .notification-badge {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: var(--colour-redorange);
      width: 1.1rem;
      height: 1.1rem;
      display: grid;
      align-items: center;
      justify-content: center;
      font-size: 0.8em;
      border-radius: var(--round);
      font-weight: bold;
    }
  }
  
  &.icon-text-vert {
    background-color: transparent;
    padding: unset;
    min-width: 2rem;
    display: flex;
    flex-direction: column;
    gap: var(--gap-s);
    --colour-element-text: var(--colour-text);
    
    svg {
      width: 1.3rem;
    }
  }
  
  &.text {
    --colour-element-text: var(--colour-text);
    padding: unset;
    background-color: transparent;
    border: unset;
    justify-content: unset;
    
    &.alert,
    &[data-alert="true"] {
      color: var(--colour-standout);
      --colour-text: var(--colour-standout);
      
      svg {
        fill: var(--colour-standout);
      }
    }
    
    &.menu-entry {
      padding: var(--inner-padding-s) 0;
      
      .theme-indicator {
        --indicator-size-mult: 0.65;
      }
      
      svg {
        width: 1.3rem;
      }
    }
    
    &[aria-disabled="true"] {
      --colour-text: var(--colour-disabled);
      opacity: 1;
      background-color: transparent;
    }
  }
  
  &.background-subtle {
    background-color: var(--colour-subtle);
  }
  
  &[aria-disabled="true"] {
    opacity: 0.5;
  }
  
  &[data-loading="true"] {
    .lds-ring {
      --loading-colour: var(--colour-element-text);
      position: absolute;
    }
  }
  
  &.drag {
    pointer-events: none;
  }
}

button.nav {
    
  svg {
    width: 1.8rem;
  }
  
  &.hero {
    padding: 0 var(--padding-m);
    height: 100%;
    border-radius: var(--corner-small);
  }
  .user-avatar {
    margin: -0.2rem;
    --avatar-size: 2.2rem;
  }
}

//hover media query
button, a {
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    outline: var(--line);
    outline-color: var(--colour-standout);
  }
  
  // &.icon:hover {
  //   background-color: var(--colour-overlay);
  // }
  
  &.empty:hover {
    background-color: var(--colour-subtle);
    
    &.darkness-hover {
      background-color: unset;
      filter: brightness(0.8);
    }
  }
}

a {
  text-decoration: none;
  &.icon-text {
    display: flex;
    flex-direction: row;
    gap: var(--gap-m);
    align-items: center;
    
    svg {
      width: 1.3rem;
    }
    
    &.small {
      svg {
        width: 1.1rem;
      }
    }
  }
  &.link {
    --colour-text: var(--colour-standout);
  }
  &.underline {
    text-decoration: underline;
  }
}

.filter-buttons {
  overflow: hidden;
  
  .filter-buttons-row {
    width: 100%;
    overflow-x: auto;
    padding-bottom: var(--inner-padding-s);
    padding-top: var(--inner-padding-s);
  }
  
  &[data-wrap="true"] {
    .filter-buttons-row {
      width: 100%;
      flex-wrap: wrap;
      padding-bottom: 0;
      padding-top: 0;
      gap: var(--gap-s)
    }
  }
  
  &[data-gradient-overflow="true"] {
    position: relative;
    
    .filter-buttons-row {
      .filter[aria-selected="true"] {
        order: -1;
      }
      .gradient-overflow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 2rem;
        background: linear-gradient(90deg, transparent, var(--colour-body) 85%, var(--colour-body));
      }
    }
  }

  &[data-no-padding="true"] {
    .filter-buttons-row {
      padding: 0;
    }
  }
  
  &[data-escape="true"] {
    margin-left: calc(var(--local-padding-value) * -1);
    width: calc(100% + var(--local-padding-value));
    
    .filter-buttons-row {
      padding: var(--local-padding-value);
      padding-bottom: var(--inner-padding-s);
      padding-top: var(--inner-padding-s);
    }
  }
}

.option-switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  button {
    border-radius: var(--corner-small);
    
    &[aria-selected="true"] {
      background-color: var(--colour-element);
      color: var(--colour-element-text);
    }
    
    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}