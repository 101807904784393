// @use '../abstracts/breakpoints' as br;

:root {
  --padding-base: 2rem;
  --inner-padding-base: 1rem;
  --gap-base: 1rem;

  --padding-xs: calc(var(--padding-base) / 2);
  --padding-s: calc(var(--padding-base) / 1.5);
  --padding-m: calc(var(--padding-base) / 1.3);
  --padding-r: var(--padding-base);
  --padding-l: calc(var(--padding-base) * 1.5);
  --padding-xl: calc(var(--padding-base) * 2);
  --padding-xxl: calc(var(--padding-base) * 4);

  --inner-padding-xs: calc(var(--inner-padding-base) / 3);
  --inner-padding-s: calc(var(--inner-padding-base) / 2);
  --inner-padding-m: calc(var(--inner-padding-base) / 1.5);
  --inner-padding-r: var(--inner-padding-base);
  --inner-padding-l: calc(var(--inner-padding-base) * 1.5);
  --inner-padding-xl: calc(var(--inner-padding-base) * 2);
  --inner-padding-xxl: calc(var(--inner-padding-base) * 2.4);

  --gap-xs: calc(var(--gap-base) / 6);
  --gap-s: calc(var(--gap-base) / 3);
  --gap-m: calc(var(--gap-base) / 2);
  --gap-r: var(--gap-base);
  --gap-l: calc(var(--gap-base) * 1.5);
  --gap-xl: calc(var(--gap-base) * 2);
  --gap-xxl: calc(var(--gap-base) * 3);
}

// @media screen and (max-width: br.$width-l) {
//   :root {
//     --padding-base: 1.5em;
//     --inner-padding-base: 0.75em;
//     --gap-base: 0.75em;
//   }
// }

// @media screen and (max-width: br.$width-m) {
//   :root {
//     --padding-base: 1em;
//     --inner-padding-base: 0.8em;
//     --padding-large: 1em;
//   }
// }

// @media screen and (min-width: br.$width-xxl) {
//   :root {
//     --padding-large: 3em;
//   }
// }
