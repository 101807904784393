.card-feed {
  .card-outer {
    padding-bottom: var(--gap-r);
  }
}

.card-outer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
  max-width: 40em;
  
  .badge-banner {
    padding-left: var(--inner-padding-m);
  }
  
  .card {
    background-color: var(--colour-body);
    border-radius: var(--corner-normal);
    overflow: hidden;
    border: var(--line);
    display: grid;
    grid-template-rows: max-content auto;
    min-height: 14rem;
    
    .card-inner {
      padding: var(--inner-padding-m);
      background-color: var(--colour-body);
      --colour-element: var(--colour-highlight);
      --colour-element-text: var(--colour-highlight-text);
      width: 100%;
    }
    
    .card-header {
      padding: var(--inner-padding-m);
      border-bottom: var(--line);
      background-color: var(--colour-body-base);
      --colour-text: var(--colour-text-base);
    }
    
    .card-thumb {
      width: 7rem;
      border-right: var(--line);
      position: relative;
      height: 100%;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }
    
    &[data-themed-header="true"] {
      .card-header {
        padding: var(--inner-padding-m);
        border-bottom: var(--line);
        background-color: var(--themed-body);
        --colour-text: var(--themed-text);
      }
    }
    
    &[data-draft="true"] {
      border-style: dashed;
      border-spacing: 1em;
    }
    &[data-hide-header="true"] {
      min-height: 12rem;
      grid-template-rows: auto;
    }
    &[data-small="true"] {
      min-height: unset;
      grid-template-rows: auto;
      
      .card-thumb {
        width: 5rem;
      }
    }
  }
}

.card-full-outer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
  max-width: 40em;
  height: 100%;
  
  .card-full {
    background-color: var(--colour-body);
    border-radius: var(--corner-normal);
    overflow: hidden;
    border: var(--line);
    display: grid;
    grid-template-rows: max-content auto max-content;
    height: 100%;

    & > :nth-child(2) {
      overflow-y: auto;
    }
    
    // invert theme colours when inside a collection
    .card[data-themed="true"] {
      --colour-body: var(--themed-text);
      --colour-text: var(--themed-body);
      
      .tag[data-themed="true"] {
        background-color: var(--themed-body);
        --colour-text: var(--themed-text);
        border: var(--themed-border, unset);
      }
    }
    .card[data-draft="true"] {
      border-color: var(--themed-body);
    }
    
    &[data-edit="true"] {
      .card-header {
        min-height: unset;
      }
    }
    
    .card-header {
      display: grid;
      grid-template-columns: minmax(16%, 7rem) auto;
      min-height: 10rem;
      position: relative;
      
      &[data-has-img="false"] {
        grid-template-columns: auto;
      }
      
      .card-thumb {
        width: 100%;
        overflow: hidden;
        position: relative;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
        }
      }
    }
    
  }
}